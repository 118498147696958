var provinceData = [{
    label: "北京市",
    value: "11"
}, {
    label: "天津市",
    value: "12"
}, {
    label: "河北省",
    value: "13"
}, {
    label: "山西省",
    value: "14"
}, {
    label: "内蒙古自治区",
    value: "15"
}, {
    label: "辽宁省",
    value: "21"
}, {
    label: "吉林省",
    value: "22"
}, {
    label: "黑龙江省",
    value: "23"
}, {
    label: "上海市",
    value: "31"
}, {
    label: "江苏省",
    value: "32"
}, {
    label: "浙江省",
    value: "33"
}, {
    label: "安徽省",
    value: "34"
}, {
    label: "福建省",
    value: "35"
}, {
    label: "江西省",
    value: "36"
}, {
    label: "山东省",
    value: "37"
}, {
    label: "河南省",
    value: "41"
}, {
    label: "湖北省",
    value: "42"
}, {
    label: "湖南省",
    value: "43"
}, {
    label: "广东省",
    value: "44"
}, {
    label: "广西壮族自治区",
    value: "45"
}, {
    label: "海南省",
    value: "46"
}, {
    label: "重庆市",
    value: "50"
}, {
    label: "四川省",
    value: "51"
}, {
    label: "贵州省",
    value: "52"
}, {
    label: "云南省",
    value: "53"
}, {
    label: "西藏自治区",
    value: "54"
}, {
    label: "陕西省",
    value: "61"
}, {
    label: "甘肃省",
    value: "62"
}, {
    label: "青海省",
    value: "63"
}, {
    label: "宁夏回族自治区",
    value: "64"
}, {
    label: "新疆维吾尔自治区",
    value: "65"
}, {
    label: "台湾",
    value: "66"
}, {
    label: "香港",
    value: "67"
}, {
    label: "澳门",
    value: "68"
}];
export default provinceData;

import request from '@/global/xhr'
// import qs from 'qs'


export function register(data){
    return request({
        url: '/ent/rest/app/company/reg',
        method: 'post',
        data: data
    })
}
var cityData = [
    [{
        label: "市辖区",
        value: "1101"
    }],
    [{
        label: "市辖区",
        value: "1201"
    }],
    [{
        label: "石家庄市",
        value: "1301"
    }, {
        label: "唐山市",
        value: "1302"
    }, {
        label: "秦皇岛市",
        value: "1303"
    }, {
        label: "邯郸市",
        value: "1304"
    }, {
        label: "邢台市",
        value: "1305"
    }, {
        label: "保定市",
        value: "1306"
    }, {
        label: "张家口市",
        value: "1307"
    }, {
        label: "承德市",
        value: "1308"
    }, {
        label: "沧州市",
        value: "1309"
    }, {
        label: "廊坊市",
        value: "1310"
    }, {
        label: "衡水市",
        value: "1311"
    }],
    [{
        label: "太原市",
        value: "1401"
    }, {
        label: "大同市",
        value: "1402"
    }, {
        label: "阳泉市",
        value: "1403"
    }, {
        label: "长治市",
        value: "1404"
    }, {
        label: "晋城市",
        value: "1405"
    }, {
        label: "朔州市",
        value: "1406"
    }, {
        label: "晋中市",
        value: "1407"
    }, {
        label: "运城市",
        value: "1408"
    }, {
        label: "忻州市",
        value: "1409"
    }, {
        label: "临汾市",
        value: "1410"
    }, {
        label: "吕梁市",
        value: "1411"
    }],
    [{
        label: "呼和浩特市",
        value: "1501"
    }, {
        label: "包头市",
        value: "1502"
    }, {
        label: "乌海市",
        value: "1503"
    }, {
        label: "赤峰市",
        value: "1504"
    }, {
        label: "通辽市",
        value: "1505"
    }, {
        label: "鄂尔多斯市",
        value: "1506"
    }, {
        label: "呼伦贝尔市",
        value: "1507"
    }, {
        label: "巴彦淖尔市",
        value: "1508"
    }, {
        label: "乌兰察布市",
        value: "1509"
    }, {
        label: "兴安盟",
        value: "1522"
    }, {
        label: "锡林郭勒盟",
        value: "1525"
    }, {
        label: "阿拉善盟",
        value: "1529"
    }],
    [{
        label: "沈阳市",
        value: "2101"
    }, {
        label: "大连市",
        value: "2102"
    }, {
        label: "鞍山市",
        value: "2103"
    }, {
        label: "抚顺市",
        value: "2104"
    }, {
        label: "本溪市",
        value: "2105"
    }, {
        label: "丹东市",
        value: "2106"
    }, {
        label: "锦州市",
        value: "2107"
    }, {
        label: "营口市",
        value: "2108"
    }, {
        label: "阜新市",
        value: "2109"
    }, {
        label: "辽阳市",
        value: "2110"
    }, {
        label: "盘锦市",
        value: "2111"
    }, {
        label: "铁岭市",
        value: "2112"
    }, {
        label: "朝阳市",
        value: "2113"
    }, {
        label: "葫芦岛市",
        value: "2114"
    }],
    [{
        label: "长春市",
        value: "2201"
    }, {
        label: "吉林市",
        value: "2202"
    }, {
        label: "四平市",
        value: "2203"
    }, {
        label: "辽源市",
        value: "2204"
    }, {
        label: "通化市",
        value: "2205"
    }, {
        label: "白山市",
        value: "2206"
    }, {
        label: "松原市",
        value: "2207"
    }, {
        label: "白城市",
        value: "2208"
    }, {
        label: "延边朝鲜族自治州",
        value: "2224"
    }],
    [{
        label: "哈尔滨市",
        value: "2301"
    }, {
        label: "齐齐哈尔市",
        value: "2302"
    }, {
        label: "鸡西市",
        value: "2303"
    }, {
        label: "鹤岗市",
        value: "2304"
    }, {
        label: "双鸭山市",
        value: "2305"
    }, {
        label: "大庆市",
        value: "2306"
    }, {
        label: "伊春市",
        value: "2307"
    }, {
        label: "佳木斯市",
        value: "2308"
    }, {
        label: "七台河市",
        value: "2309"
    }, {
        label: "牡丹江市",
        value: "2310"
    }, {
        label: "黑河市",
        value: "2311"
    }, {
        label: "绥化市",
        value: "2312"
    }, {
        label: "大兴安岭地区",
        value: "2327"
    }],
    [{
        label: "市辖区",
        value: "3101"
    }],
    [{
        label: "南京市",
        value: "3201"
    }, {
        label: "无锡市",
        value: "3202"
    }, {
        label: "徐州市",
        value: "3203"
    }, {
        label: "常州市",
        value: "3204"
    }, {
        label: "苏州市",
        value: "3205"
    }, {
        label: "南通市",
        value: "3206"
    }, {
        label: "连云港市",
        value: "3207"
    }, {
        label: "淮安市",
        value: "3208"
    }, {
        label: "盐城市",
        value: "3209"
    }, {
        label: "扬州市",
        value: "3210"
    }, {
        label: "镇江市",
        value: "3211"
    }, {
        label: "泰州市",
        value: "3212"
    }, {
        label: "宿迁市",
        value: "3213"
    }],
    [{
        label: "杭州市",
        value: "3301"
    }, {
        label: "宁波市",
        value: "3302"
    }, {
        label: "温州市",
        value: "3303"
    }, {
        label: "嘉兴市",
        value: "3304"
    }, {
        label: "湖州市",
        value: "3305"
    }, {
        label: "绍兴市",
        value: "3306"
    }, {
        label: "金华市",
        value: "3307"
    }, {
        label: "衢州市",
        value: "3308"
    }, {
        label: "舟山市",
        value: "3309"
    }, {
        label: "台州市",
        value: "3310"
    }, {
        label: "丽水市",
        value: "3311"
    }],
    [{
        label: "合肥市",
        value: "3401"
    }, {
        label: "芜湖市",
        value: "3402"
    }, {
        label: "蚌埠市",
        value: "3403"
    }, {
        label: "淮南市",
        value: "3404"
    }, {
        label: "马鞍山市",
        value: "3405"
    }, {
        label: "淮北市",
        value: "3406"
    }, {
        label: "铜陵市",
        value: "3407"
    }, {
        label: "安庆市",
        value: "3408"
    }, {
        label: "黄山市",
        value: "3410"
    }, {
        label: "滁州市",
        value: "3411"
    }, {
        label: "阜阳市",
        value: "3412"
    }, {
        label: "宿州市",
        value: "3413"
    }, {
        label: "六安市",
        value: "3415"
    }, {
        label: "亳州市",
        value: "3416"
    }, {
        label: "池州市",
        value: "3417"
    }, {
        label: "宣城市",
        value: "3418"
    }],
    [{
        label: "福州市",
        value: "3501"
    }, {
        label: "厦门市",
        value: "3502"
    }, {
        label: "莆田市",
        value: "3503"
    }, {
        label: "三明市",
        value: "3504"
    }, {
        label: "泉州市",
        value: "3505"
    }, {
        label: "漳州市",
        value: "3506"
    }, {
        label: "南平市",
        value: "3507"
    }, {
        label: "龙岩市",
        value: "3508"
    }, {
        label: "宁德市",
        value: "3509"
    }],
    [{
        label: "南昌市",
        value: "3601"
    }, {
        label: "景德镇市",
        value: "3602"
    }, {
        label: "萍乡市",
        value: "3603"
    }, {
        label: "九江市",
        value: "3604"
    }, {
        label: "新余市",
        value: "3605"
    }, {
        label: "鹰潭市",
        value: "3606"
    }, {
        label: "赣州市",
        value: "3607"
    }, {
        label: "吉安市",
        value: "3608"
    }, {
        label: "宜春市",
        value: "3609"
    }, {
        label: "抚州市",
        value: "3610"
    }, {
        label: "上饶市",
        value: "3611"
    }],
    [{
        label: "济南市",
        value: "3701"
    }, {
        label: "青岛市",
        value: "3702"
    }, {
        label: "淄博市",
        value: "3703"
    }, {
        label: "枣庄市",
        value: "3704"
    }, {
        label: "东营市",
        value: "3705"
    }, {
        label: "烟台市",
        value: "3706"
    }, {
        label: "潍坊市",
        value: "3707"
    }, {
        label: "济宁市",
        value: "3708"
    }, {
        label: "泰安市",
        value: "3709"
    }, {
        label: "威海市",
        value: "3710"
    }, {
        label: "日照市",
        value: "3711"
    }, {
        label: "莱芜市",
        value: "3712"
    }, {
        label: "临沂市",
        value: "3713"
    }, {
        label: "德州市",
        value: "3714"
    }, {
        label: "聊城市",
        value: "3715"
    }, {
        label: "滨州市",
        value: "3716"
    }, {
        label: "菏泽市",
        value: "3717"
    }],
    [{
        label: "郑州市",
        value: "4101"
    }, {
        label: "开封市",
        value: "4102"
    }, {
        label: "洛阳市",
        value: "4103"
    }, {
        label: "平顶山市",
        value: "4104"
    }, {
        label: "安阳市",
        value: "4105"
    }, {
        label: "鹤壁市",
        value: "4106"
    }, {
        label: "新乡市",
        value: "4107"
    }, {
        label: "焦作市",
        value: "4108"
    }, {
        label: "濮阳市",
        value: "4109"
    }, {
        label: "许昌市",
        value: "4110"
    }, {
        label: "漯河市",
        value: "4111"
    }, {
        label: "三门峡市",
        value: "4112"
    }, {
        label: "南阳市",
        value: "4113"
    }, {
        label: "商丘市",
        value: "4114"
    }, {
        label: "信阳市",
        value: "4115"
    }, {
        label: "周口市",
        value: "4116"
    }, {
        label: "驻马店市",
        value: "4117"
    }, {
        label: "省直辖县级行政区划",
        value: "4190"
    }],
    [{
        label: "武汉市",
        value: "4201"
    }, {
        label: "黄石市",
        value: "4202"
    }, {
        label: "十堰市",
        value: "4203"
    }, {
        label: "宜昌市",
        value: "4205"
    }, {
        label: "襄阳市",
        value: "4206"
    }, {
        label: "鄂州市",
        value: "4207"
    }, {
        label: "荆门市",
        value: "4208"
    }, {
        label: "孝感市",
        value: "4209"
    }, {
        label: "荆州市",
        value: "4210"
    }, {
        label: "黄冈市",
        value: "4211"
    }, {
        label: "咸宁市",
        value: "4212"
    }, {
        label: "随州市",
        value: "4213"
    }, {
        label: "恩施土家族苗族自治州",
        value: "4228"
    }, {
        label: "省直辖县级行政区划",
        value: "4290"
    }],
    [{
        label: "长沙市",
        value: "4301"
    }, {
        label: "株洲市",
        value: "4302"
    }, {
        label: "湘潭市",
        value: "4303"
    }, {
        label: "衡阳市",
        value: "4304"
    }, {
        label: "邵阳市",
        value: "4305"
    }, {
        label: "岳阳市",
        value: "4306"
    }, {
        label: "常德市",
        value: "4307"
    }, {
        label: "张家界市",
        value: "4308"
    }, {
        label: "益阳市",
        value: "4309"
    }, {
        label: "郴州市",
        value: "4310"
    }, {
        label: "永州市",
        value: "4311"
    }, {
        label: "怀化市",
        value: "4312"
    }, {
        label: "娄底市",
        value: "4313"
    }, {
        label: "湘西土家族苗族自治州",
        value: "4331"
    }],
    [{
        label: "广州市",
        value: "4401"
    }, {
        label: "韶关市",
        value: "4402"
    }, {
        label: "深圳市",
        value: "4403"
    }, {
        label: "珠海市",
        value: "4404"
    }, {
        label: "汕头市",
        value: "4405"
    }, {
        label: "佛山市",
        value: "4406"
    }, {
        label: "江门市",
        value: "4407"
    }, {
        label: "湛江市",
        value: "4408"
    }, {
        label: "茂名市",
        value: "4409"
    }, {
        label: "肇庆市",
        value: "4412"
    }, {
        label: "惠州市",
        value: "4413"
    }, {
        label: "梅州市",
        value: "4414"
    }, {
        label: "汕尾市",
        value: "4415"
    }, {
        label: "河源市",
        value: "4416"
    }, {
        label: "阳江市",
        value: "4417"
    }, {
        label: "清远市",
        value: "4418"
    }, {
        label: "东莞市",
        value: "4419"
    }, {
        label: "中山市",
        value: "4420"
    }, {
        label: "潮州市",
        value: "4451"
    }, {
        label: "揭阳市",
        value: "4452"
    }, {
        label: "云浮市",
        value: "4453"
    }],
    [{
        label: "南宁市",
        value: "4501"
    }, {
        label: "柳州市",
        value: "4502"
    }, {
        label: "桂林市",
        value: "4503"
    }, {
        label: "梧州市",
        value: "4504"
    }, {
        label: "北海市",
        value: "4505"
    }, {
        label: "防城港市",
        value: "4506"
    }, {
        label: "钦州市",
        value: "4507"
    }, {
        label: "贵港市",
        value: "4508"
    }, {
        label: "玉林市",
        value: "4509"
    }, {
        label: "百色市",
        value: "4510"
    }, {
        label: "贺州市",
        value: "4511"
    }, {
        label: "河池市",
        value: "4512"
    }, {
        label: "来宾市",
        value: "4513"
    }, {
        label: "崇左市",
        value: "4514"
    }],
    [{
        label: "海口市",
        value: "4601"
    }, {
        label: "三亚市",
        value: "4602"
    }, {
        label: "三沙市",
        value: "4603"
    }, {
        label: "儋州市",
        value: "4604"
    }, {
        label: "省直辖县级行政区划",
        value: "4690"
    }],
    [{
        label: "市辖区",
        value: "5001"
    }, {
        label: "县",
        value: "5002"
    }],
    [{
        label: "成都市",
        value: "5101"
    }, {
        label: "自贡市",
        value: "5103"
    }, {
        label: "攀枝花市",
        value: "5104"
    }, {
        label: "泸州市",
        value: "5105"
    }, {
        label: "德阳市",
        value: "5106"
    }, {
        label: "绵阳市",
        value: "5107"
    }, {
        label: "广元市",
        value: "5108"
    }, {
        label: "遂宁市",
        value: "5109"
    }, {
        label: "内江市",
        value: "5110"
    }, {
        label: "乐山市",
        value: "5111"
    }, {
        label: "南充市",
        value: "5113"
    }, {
        label: "眉山市",
        value: "5114"
    }, {
        label: "宜宾市",
        value: "5115"
    }, {
        label: "广安市",
        value: "5116"
    }, {
        label: "达州市",
        value: "5117"
    }, {
        label: "雅安市",
        value: "5118"
    }, {
        label: "巴中市",
        value: "5119"
    }, {
        label: "资阳市",
        value: "5120"
    }, {
        label: "阿坝藏族羌族自治州",
        value: "5132"
    }, {
        label: "甘孜藏族自治州",
        value: "5133"
    }, {
        label: "凉山彝族自治州",
        value: "5134"
    }],
    [{
        label: "贵阳市",
        value: "5201"
    }, {
        label: "六盘水市",
        value: "5202"
    }, {
        label: "遵义市",
        value: "5203"
    }, {
        label: "安顺市",
        value: "5204"
    }, {
        label: "毕节市",
        value: "5205"
    }, {
        label: "铜仁市",
        value: "5206"
    }, {
        label: "黔西南布依族苗族自治州",
        value: "5223"
    }, {
        label: "黔东南苗族侗族自治州",
        value: "5226"
    }, {
        label: "黔南布依族苗族自治州",
        value: "5227"
    }],
    [{
        label: "昆明市",
        value: "5301"
    }, {
        label: "曲靖市",
        value: "5303"
    }, {
        label: "玉溪市",
        value: "5304"
    }, {
        label: "保山市",
        value: "5305"
    }, {
        label: "昭通市",
        value: "5306"
    }, {
        label: "丽江市",
        value: "5307"
    }, {
        label: "普洱市",
        value: "5308"
    }, {
        label: "临沧市",
        value: "5309"
    }, {
        label: "楚雄彝族自治州",
        value: "5323"
    }, {
        label: "红河哈尼族彝族自治州",
        value: "5325"
    }, {
        label: "文山壮族苗族自治州",
        value: "5326"
    }, {
        label: "西双版纳傣族自治州",
        value: "5328"
    }, {
        label: "大理白族自治州",
        value: "5329"
    }, {
        label: "德宏傣族景颇族自治州",
        value: "5331"
    }, {
        label: "怒江傈僳族自治州",
        value: "5333"
    }, {
        label: "迪庆藏族自治州",
        value: "5334"
    }],
    [{
        label: "拉萨市",
        value: "5401"
    }, {
        label: "日喀则市",
        value: "5402"
    }, {
        label: "昌都市",
        value: "5403"
    }, {
        label: "林芝市",
        value: "5404"
    }, {
        label: "山南市",
        value: "5405"
    }, {
        label: "那曲地区",
        value: "5424"
    }, {
        label: "阿里地区",
        value: "5425"
    }],
    [{
        label: "西安市",
        value: "6101"
    }, {
        label: "铜川市",
        value: "6102"
    }, {
        label: "宝鸡市",
        value: "6103"
    }, {
        label: "咸阳市",
        value: "6104"
    }, {
        label: "渭南市",
        value: "6105"
    }, {
        label: "延安市",
        value: "6106"
    }, {
        label: "汉中市",
        value: "6107"
    }, {
        label: "榆林市",
        value: "6108"
    }, {
        label: "安康市",
        value: "6109"
    }, {
        label: "商洛市",
        value: "6110"
    }],
    [{
        label: "兰州市",
        value: "6201"
    }, {
        label: "嘉峪关市",
        value: "6202"
    }, {
        label: "金昌市",
        value: "6203"
    }, {
        label: "白银市",
        value: "6204"
    }, {
        label: "天水市",
        value: "6205"
    }, {
        label: "武威市",
        value: "6206"
    }, {
        label: "张掖市",
        value: "6207"
    }, {
        label: "平凉市",
        value: "6208"
    }, {
        label: "酒泉市",
        value: "6209"
    }, {
        label: "庆阳市",
        value: "6210"
    }, {
        label: "定西市",
        value: "6211"
    }, {
        label: "陇南市",
        value: "6212"
    }, {
        label: "临夏回族自治州",
        value: "6229"
    }, {
        label: "甘南藏族自治州",
        value: "6230"
    }],
    [{
        label: "西宁市",
        value: "6301"
    }, {
        label: "海东市",
        value: "6302"
    }, {
        label: "海北藏族自治州",
        value: "6322"
    }, {
        label: "黄南藏族自治州",
        value: "6323"
    }, {
        label: "海南藏族自治州",
        value: "6325"
    }, {
        label: "果洛藏族自治州",
        value: "6326"
    }, {
        label: "玉树藏族自治州",
        value: "6327"
    }, {
        label: "海西蒙古族藏族自治州",
        value: "6328"
    }],
    [{
        label: "银川市",
        value: "6401"
    }, {
        label: "石嘴山市",
        value: "6402"
    }, {
        label: "吴忠市",
        value: "6403"
    }, {
        label: "固原市",
        value: "6404"
    }, {
        label: "中卫市",
        value: "6405"
    }],
    [{
        label: "乌鲁木齐市",
        value: "6501"
    }, {
        label: "克拉玛依市",
        value: "6502"
    }, {
        label: "吐鲁番市",
        value: "6504"
    }, {
        label: "哈密市",
        value: "6505"
    }, {
        label: "昌吉回族自治州",
        value: "6523"
    }, {
        label: "博尔塔拉蒙古自治州",
        value: "6527"
    }, {
        label: "巴音郭楞蒙古自治州",
        value: "6528"
    }, {
        label: "阿克苏地区",
        value: "6529"
    }, {
        label: "克孜勒苏柯尔克孜自治州",
        value: "6530"
    }, {
        label: "喀什地区",
        value: "6531"
    }, {
        label: "和田地区",
        value: "6532"
    }, {
        label: "伊犁哈萨克自治州",
        value: "6540"
    }, {
        label: "塔城地区",
        value: "6542"
    }, {
        label: "阿勒泰地区",
        value: "6543"
    }, {
        label: "自治区直辖县级行政区划",
        value: "6590"
    }],
    [{
        label: "台北",
        value: "6601"
    }, {
        label: "高雄",
        value: "6602"
    }, {
        label: "基隆",
        value: "6603"
    }, {
        label: "台中",
        value: "6604"
    }, {
        label: "台南",
        value: "6605"
    }, {
        label: "新竹",
        value: "6606"
    }, {
        label: "嘉义",
        value: "6607"
    }, {
        label: "宜兰",
        value: "6608"
    }, {
        label: "桃园",
        value: "6609"
    }, {
        label: "苗栗",
        value: "6610"
    }, {
        label: "彰化",
        value: "6611"
    }, {
        label: "南投",
        value: "6612"
    }, {
        label: "云林",
        value: "6613"
    }, {
        label: "屏东",
        value: "6614"
    }, {
        label: "台东",
        value: "6615"
    }, {
        label: "花莲",
        value: "6616"
    }, {
        label: "澎湖",
        value: "6617"
    }],
    [{
        label: "香港岛",
        value: "6701"
    }, {
        label: "九龙",
        value: "6702"
    }, {
        label: "新界",
        value: "6703"
    }],
    [{
        label: "澳门半岛",
        value: "6801"
    }, {
        label: "氹仔岛",
        value: "6802"
    }, {
        label: "路环岛",
        value: "6803"
    }, {
        label: "路氹城",
        value: "6804"
    }]
];
export default cityData;

<template>
  <div class="container">
    <!-- <el-button @click="onClick">
        了解更多
      </el-button> -->
    <!-- 头部大图 -->
    <div class="header">
      <img
        v-lazy="require('../../../assets/images/background.png')"
        class="image" />
      <div class="header-detail">
        <div class="title-content">
          <div class="left" @click="toPC()">
            <el-image
              :src="require('../../../assets/images/logo-gw.png')"
              class="left-icon"
            ></el-image>
            <span class="left-title">礼帽出行企业版</span>
          </div>
          <div class="right">
            <el-button class="right-btn" @click="toPC()">
            登录 
           </el-button>
          </div>
        </div>
        <div class="header-main">
          <div class="main-title">
            礼帽出行企业版
          </div>
          <!-- <div class="main-detail">
            为企业提供一体化出行解决方案，出行管理更简单
          </div> -->
          <div class="main-btn">
            <el-button class="btn" @click="onClick">免费获取用车方案</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 01服务优势 -->
    <div class="one-content">
      <div class="content-limit">
        <div class="main-one">
          <div class="one-header">
            <img
              v-lazy="require('../../../assets/images/01.png')"
              class="image" />
            <div class="one-text">
              <div class="text-detail">服务优势</div>
            </div>
          </div>
        </div>
        <!-- 1 -->
        <div class="fwys-main-1">
            <div class="item">
               <img
              v-lazy="require('../../../assets/images/01-00.png')"
              class="fwys-image" /> 
            </div>
            <div class="item">
                <div class="content">
                     <div class="fwys-title">大品牌，值得信赖</div>
                    <div class="fwys-line"></div>
                    <div class="fwys-detail">
                        礼帽出行，专注预约订制出行服务。
                    </div>
                    <div class="fwys-detail" style="margin-top:3px">
                        由吉利控股集团旗下LEVC全新打造。
                    </div>
                </div>
            </div>
        </div>
        <!-- 2 -->
        <div class="fwys-main bg-color">
          <div class="fwys-item">
            <img
              v-lazy="require('../../../assets/images/01-02.png')"
              class="fwys-image" />
            <div class="fwys-content margin-top">
              <div class="fwys-title">自营车辆，安全合规</div>
              <div class="fwys-line"></div>
              <div class="fwys-detail">
                车辆新，定期维护，保障运营车辆的品质和安全，让出行无后顾之忧
              </div>
            </div>
          </div>
          <div class="fwys-item">
            <img
              v-lazy="require('../../../assets/images/01-03.png')"
              class="fwys-image" />
            <div class="fwys-content margin-top">
              <div class="fwys-title">出行管家，服务至上</div>
              <div class="fwys-line"></div>
              <div class="fwys-detail">
                统一培训，全面导入英国伦敦出租车司机服务标准资质培训认证体系，持证上岗人车实名认证，服务标准监管，优胜略汰
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多场景覆盖企业用车需求 -->
    <div class="two-content">
      <div class="main-one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/02.png')"
            class="image" />
          <div class="one-text">
            <div class="text-detail">多场景覆盖企业用车需求</div>
          </div>
        </div>
      </div>
      <div class="khyc">
        <span class="khyc-left"></span>
        <span class="khyc-khyc">客户用车</span>
        <span class="khyc-right"></span>
      </div>
      <div class="khyc-main">
        <!-- <el-image
        :src="khycType===1?require('../../../assets/images/02-01.png'):khycType===2?require('../../../assets/images/02-02.png'):khycType===3?require('../../../assets/images/02-03.png'):require('../../../assets/images/02-04.png')"
        class="khyc-image"
      ></el-image> -->
        <img
          v-show="khycType === 1"
          v-lazy="require('../../../assets/images/02-01.png')"
          class="khyc-image" />
        <img
          v-show="khycType === 2"
          v-lazy="require('../../../assets/images/02-02.png')"
          class="khyc-image" />
        <img
          v-show="khycType === 3"
          v-lazy="require('../../../assets/images/02-03.png')"
          class="khyc-image" />
        <img
          v-show="khycType === 4"
          v-lazy="require('../../../assets/images/02-04.png')"
          class="khyc-image" />
      <div class="khyc-top-main">
        <div class="khyc-top">
          <div class="khyc-items">
            <div
              class="khyc-top-item"
              :class="{ 'khyc-top-item-actived': khycType === 1 }"
              @mouseenter="mouseEnterKHYC(1)"
            >
              <span class="item-big">01</span
              ><span class="item-small">接待用车</span>
            </div>
            <div
              class="khyc-top-item"
              :class="{ 'khyc-top-item-actived': khycType === 2 }"
              @mouseenter="mouseEnterKHYC(2)"
            >
              <span class="item-big">02</span
              ><span class="item-small">会议用车</span>
            </div>
            <div
              class="khyc-top-item"
              :class="{ 'khyc-top-item-actived': khycType === 3 }"
              @mouseenter="mouseEnterKHYC(3)"
            >
              <span class="item-big">03</span
              ><span class="item-small">营销用车</span>
            </div>
            <div
              class="khyc-top-item"
              :class="{ 'khyc-top-item-actived': khycType === 4 }"
              @mouseenter="mouseEnterKHYC(4)"
            >
              <span class="item-big">04</span
              ><span class="item-small">代叫用车</span>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="khyc">
        <span class="khyc-left"></span>
        <span class="khyc-khyc">员工用车</span>
        <span class="khyc-right"></span>
      </div>
      <!-- 员工用车 -->
      <div class="ygyc-main">
        <div class="ygyc-item">
            <div class="item1">
              <el-image
                :src="require('../../../assets/images/yg02-01.png')"
                class="ygyc-image"
              ></el-image>
              <div class="content">日常用车</div>
            </div>
             <div class="item1">
              <el-image
                :src="require('../../../assets/images/yg02-02.png')"
                class="ygyc-image"
              ></el-image>
              <div class="content">加班用车</div>
            </div>
             <div class="item1" style="margin-left: 30px">
              <el-image
                :src="require('../../../assets/images/yg02-03.png')"
                class="ygyc-image"
              ></el-image>
              <div class="content">出差用车</div>
            </div>
            <div class="item1">
              <el-image
                :src="require('../../../assets/images/yg02-04.png')"
                class="ygyc-image"
              ></el-image>
              <div class="content">活动用车</div>
            </div>
        </div>
        <!-- <div class="ygyc-text">
          <div class="text-item">日常用车</div>
          <div class="text-item">加班用车</div>
          <div class="text-item">出差用车</div>
          <div class="text-item">活动用车</div>
        </div> -->
        <div class="ygyc-btn">
          <el-button class="btn" @click="onClick">立即了解</el-button>
        </div>
      </div>
    </div>
    <!-- 专属座驾 -->
    <div class="thirst-content">
      <div class="content-limit">
          <div class="main-one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/03.png')"
            class="image" />
          <div class="one-text">
            <div class="text-detail">专属座驾 LEVC TX-5</div>
          </div>
        </div>
      </div>
      <div class="content-main">
        <!-- <el-carousel @change="changeCarousel" :autoplay="false" type="card" indicator-position="none" arrow="never" class="carousel-box">
          <el-carousel-item v-for="(item, index) in imageList" :key="index" class="carousel-item">
                 <el-image :src="item.src" class="image">
                </el-image> 
          </el-carousel-item>
        </el-carousel> -->

        <el-carousel
          :initial-index="indexCar"
          height="500px"
          type="card"
          indicator-position="none"
          arrow="never"
          class="carousel-box"
          :pause-on-hover="false"
          @change='changeCarousel'
        >
          <el-carousel-item
            v-for="(item, index) in imageList"
            :key="index"
            class="carousel-item"
          >
            <div class="margin-bottom: 50px">
              <el-image :src="item.src" class="image"> </el-image>
            </div>
            <div class="content-text">
              <div
                style="margin-top: 30px;font-size: 18px;color: #333333;font-weight: 600;"
                :style="{color:(indexCar===index?'#266FE8 ':'#333333'),'font-size':(indexCar===index?'28px':'18px')}"
              >
                {{ item.contentTitle }}
              </div>
              <div
                style="margin-top: 10px;font-size: 14px;color: #666666;"
                :style="{'font-size':(indexCar===index?'20px':'14px')}"
              >
                {{ item.contentDetail }}
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
      
    </div>

    <!-- 服务支持 -->
    <div class="four-content">
      <div class="content-limit">
        <div class="main-one">
          <div class="one-header">
            <el-image
              :src="require('../../../assets/images/04.png')"
              class="image"
            ></el-image>
            <div class="one-text">
              <div class="text-detail">服务支持</div>
            </div>
          </div>
        </div>
        <div class="content-box">
          <div class="content-item">
            <el-image
              :src="require('../../../assets/images/04-01.png')"
              class="image"
            ></el-image>
            <div class="item-title">运营支持</div>
            <div class="item-line"></div>
            <div class="jfy-center">
                <div class="item-detail">
              提供专业、优质、温馨的用车体验，满足多场景、个性化出行需求。
            </div>
            </div>
          </div>
          <div class="content-item">
            <el-image
              :src="require('../../../assets/images/04-02.png')"
              class="image"
            ></el-image>
            <div class="item-title">客服支持</div>
            <div class="item-line"></div>
            <div class="jfy-center">
            <div class="item-detail">
              提供专业客服、VIP专属客服经理、业务受理咨询、账单及费用查询、投诉及建议、紧急救助等服务。
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div style="text-align:center">
        <span>浙江礼帽出行科技有限公司 copyright@2022</span>
        <a href="https://beian.miit.gov.cn" target="_blank">
        <span style="padding-left:30px;color:#ffffff">浙ICP备2021011876号-1</span>
        </a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802011694" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img
          style="vertical-align: middle;padding-left:30px"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAC3FBMVEUAAAD+/ODz6Kr//+PeqFfYrn3x167k0JXoxnyaaVzhs2ifaFXbrGLkvFnpyF7v2X/kwm3cp1nhsGfqw3rZqG3ntVzjrFPt3oDjvGnfr2fbnFGti3q0lH7ktoLryXn9v1T4znr/74bnvGz034v+2I/ktoDz6ZLkwY/Dfz7buoftzYbq2IPr0pjs3bLv6KPRrnbKhFv79ND488n/+dDZr4Lx38f/+cH/95f42oL7/97s2Y3++uzw1rvTk3DmuloAAHkBAm7uzWYAAGXktV3qvFr/0ljksE7fo0rWHxhrdocAAIAABHf143Pyy27w1GwGA2jtymHpwWDqxV/qyVyTeFrrwFflwFPislP+xVLpsErbmUfVkEbysETemUTpgj7ThT3XdTg5FDjdhTXWZTDaTCm7TCbTOCLXPiD9LA/QFg3UAwnOAQOEj5kcPpdyhZSptJEACJFpfo4AG44XMInFvYfTvIejmYSVkINyeoJzdoK9un6SjX7FrnwAEHp8enny2HjWwHjKtnhcX3jYzHeNhnfu2HWUjHWsonPNwnH70m9WTm8AAW//723pym3dtmn/0mbnxGa0o2ZeWWb8zGT/4mPtwmJuYmL/22D/vmB5ZGC9kF7/2l0MAF3uyFqnjVn4xFjYnli0mVi5i1jiqVfyyVbmtlbXkVNUOFPlvFLpt1LNrFKjfVLuvlBgHlDsuU/ouU9ONU/ov05ODk7/2E02Gk3jqkqEaUr/tUngjkf7n0bXikb6xERCJETdn0LckUG1gD/ooD3Ulj3jkz3TZT3WjjzOeDqBWDr3pDnglTlMADnbbTf2gjbkbzaTYDZpAjbplzTtcTTEazPXXzOeXzDscS3MPi38jizJWSrVSCrrXynzfCjVdCjZRyjTQCbFUiTlYCPXPSHLPSHWMR/wXh7iRh7GPh3PLBrSIRrWGhfMJxPGJxPRDBG/ABG2ABCxDg7BDAvEGArZAAbJAALPAADa4ry/AAAAPnRSTlMACEIaxqxpAvv7+ff19PDs7Ovn5uXk5OHg29LRy8fEw8G+vLqysaufnJiVk4yDfG9dXFpMSEFBNTApJyEcFO3QiBQAAAFzSURBVBjTYoACZjYZaTZmBmRgxsp9+di21ZysxggxxlmJZy/ev9LXnriIEa5VYUPIray0lOyd+ctVoKKWXFsmXXvu8exO5vsZnnuErcCC5m1e8x5nPXrxOu3TzSqHFguQmI18tff+Jx89HqR7fE5v7q5TtAYK6h8v81p4Ovv6wbAdmRc6HMpddYGCmudrCqbtTn2anHBq15SZ9iUx6kBBkSTfXIfUuBsPL909c9i/uP6EJFAQMJ6j2/Ps32Yk30uIy3jjXxgRLwEUVN07ubTo5LsPr16mXD1X29gZrgUUlN23uD/H28lp09o5TvYVs523ygEFORYsO+TbEOI5cVVTV+XUA1Fu/EBBoxXu0bfnT98cEePa45oUHR7MBHK9IV9Y/BFHFzc7R7/YqF4BsBiDqVBw0NLQoMAAF3c7vwmCEEFln1ZnZxe3wJWx7nZ2jj5qkNDU5l2/ZE3kusjQuRsDxPXYoQFqa6DBIiUmyqKkYwIWAgD35oZAL/mkFwAAAABJRU5ErkJggg=="
          alt=""
        />
        <span style="padding-left:5px;color:#ffffff">
            浙公网安备 33010802011694号
        </span>
        </a>
      </div>
    </div>
    <el-dialog v-model="dialogVisible">
      <div class="dialog-content">
        <div class="content-title">请提交联系方式</div>
        <el-form ref="ruleFormRef" :model="form" style="margin-top:24px" label-width="68px">
          <el-form-item label="企业名称">
            <el-input v-model="form.companyName" maxlength="20" placeholder="请输入企业名称"/>
          </el-form-item>
          <el-form-item label="所在地">
            <el-row :gutter="5">
              <el-col :span="12">
                <el-select
                  v-model="form.province"
                  placeholder="省份"
                  @change="changePsel"
                >
                  <el-option
                    v-for="item in provinceData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select
                  :disabled="!form.province"
                  v-model="form.city"
                  placeholder="城市"
                  size="large"
                >
                  <el-option
                    v-for="item in cityDataSel"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="企业规模">
            <el-select
              style="width:100%"
              v-model="form.scale"
              placeholder="请选择"
            >
              <el-option
                v-for="item in columns"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.mobile" maxlength="11" placeholder="请输入您的手机号"/>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="form.personName" maxlength="20" placeholder="请输入您的姓名"/>
          </el-form-item>
        </el-form>
        <el-button
          :disabled="isDisabled"
          class="btn"
          :style="{
            background: isDisabled ? '#E7E7E7' : '#152FBF',
            color: isDisabled ? '#000000' : '#ffffff',
          }"
          type="primary"
          @click="submit(ruleFormRef)"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { useStore } from "vuex";
// import Footer from "./footer.vue";
import provinceData from "@/utils/province";
import cityData from "@/utils/city";
import { ref, reactive, watch } from "vue";
import { isMobile } from "@/utils/validate";
import { ElMessage } from "element-plus";
// import { t } from "element-plus/lib/locale";
const src1 = require("../../../assets/images/03-01.png");
const src2 = require("../../../assets/images/03-02.png");
const src3 = require("../../../assets/images/03-03.png");

// import type { FormInstance } from 'element-plus'
import { register } from '@/api/home'
export default {
  components: {
    // Footer
  },
  setup() {
    const cityDataSel = ref("");
    const changePsel = (val) => {
      form.city = "";
      getCity(val);
    };
    //根据省份获取市
    const getCity = (value) => {
      if (!value) {
        cityDataSel.value = "";
        return false;
      }
      provinceData.forEach((item, index) => {
        if (item.value === value) {
          cityDataSel.value = cityData[index];
          //   console.log("city:", JSON.stringify(cityDataSel));
        }
      });
    };
    //dialog弹框
    const dialogVisible = ref(false);
    //表单数据
    const form = reactive({
      companyName: "",
      province: "",
      city: "",
      scale: "",
      mobile: "",
      personName: "",
      infoSource:40
    });
    const columns = reactive([
      {
        label: "1-50人",
        value: '0',
      },
      {
        label: "51-100人",
        value: '1',
      },
      {
        label: "101-500人",
        value: '2',
      },
      {
        label: "501-1000人",
        value: '3',
      },
      {
        label: "1000人以上",
        value: '4',
      }
    ]);
    const isDisabled = ref(true);
    watch(form, (newValue) => {
    //   console.log(JSON.stringify(newValue));
      if (
        newValue.companyName &&
        newValue.province &&
        newValue.city &&
        newValue.scale &&
        isMobile(newValue.mobile) &&
        newValue.personName
      ) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });
    const submit = () => {
      dialogVisible.value = false;
       form.province = `${form.province}0000`
      form.city = `${form.city}00`
      register(form).then(res=>{
          if(res.code===1000){
              ElMessage({
        message: "提交成功，我们会尽快联系您，请保持手机畅通~",
        grouping: true,
        type: "success",
        // duration: 1000000,
                    });
          } else {
              ElMessage({
        message: res.message,
        grouping: true,
        type: "error",
        // duration: 1000000,
      });
          }
          console.log("res",JSON.stringify(res))
          
      })
      console.log('form',JSON.stringify(form));
    //   formEl.resetFields()
      
      console.log("form:", JSON.stringify(form));
    };
    const onClick = () => {
      form.province = ''
      form.city = ''
      form.companyName = ''
      form.scale = ''
      form.mobile = ''
      form.personName = ''
      dialogVisible.value = true;
    //   form.province = `${form.province}0000`
    //   form.city = `${form.city}00`
      
    //   register(form)
    //   .then(res=>{
    //       console.log('res:',JSON.stringify(res))
    //   })
      //   ElMessage({
      //     message: "提交成功，我们会尽快联系您，请保持手机畅通~",
      //     grouping: true,
      //     type: "success",
      //     duration: 1000000,
      //   });
    };
    //走马灯
    const index = ref(0);
    const onChange = (event) => {
      //   console.log(event);
      index.value = event;
    };
    // 车型特点鼠标滑过类型
    const type = ref(1);
    //用车切换
    const change = ref("");
    //客户用车切换
    const khycType = ref(1);
    //03走马灯
    const indexCar = ref(1);
    return {
      //   province, //select省
      //   city, //select市
      provinceData,
      cityData,
      cityDataSel, //根据省份索引出的city数组
      changePsel,
      getCity,
      //dialog弹框
      dialogVisible,
      form,
      columns,
      isDisabled,
      isMobile,
      submit,
      onClick,
      //走马灯
      index,
      onChange,
      change,

      //车型特点
      type,
      //   客户用车
      khycType,
      indexCar,
    };
  },
  data() {
    return {
      imageList: [
        {
          src: src1,
          contentTitle: "更舒适的乘坐体验",
          contentDetail: "对开门 大空间",
        },
        {
          src: src2,
          contentTitle: "更私密的洽谈",
          contentDetail: "高强度隔断 语音系统",
        },
        {
          src: src3,
          contentTitle: "更安全的出行",
          contentDetail: "全铝车身 极致工艺",
        },
      ],
      //   indexCar: 0,
    };
  },
  mounted() {
    //   this.init()
  },
  methods: {
    //   客户用车切换
    mouseEnterKHYC(type) {
      this.khycType = type;
    },
    //   车型特点切换
    mouserEnter(type) {
      //   console.log("鼠标进入");
      //   this.change = item;
      this.type = type;
      console.log("type:", type);
    },
    changeCarousel(index) {
    //   console.log("index:", index);
      this.indexCar = index;
    },
    setActiveItem() {
      console.log("手动");
    },
    toPC(){
        window.open("https://es.limaocx.com/admin/", "_blank")  //test环境
        // window.open("https://es-dev.limaocx.com/admin/", "_blank")   //dev
    }
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/scss/home.scss";
</style>

<style lang="scss">
.el-dialog {
  margin-top: 200px !important;
  width: 440px !important;
  border-radius: 12px;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #000000;
  width: 16px;
  height: 16px;
}
.el-form-item__label {
  color: #000000;
  font-size: 14px;
}
.el-message--success, .el-message--error{
  background-color: #ffffff;
  border-color: #dcdcdc;
  --el-message-font-color: #000000;
}
.el-message .el-icon-success {
  color: #00a870;
}
.el-message{
    min-width: 300px;
}
.el-icon-success{
    color: #E34D59;
}
.el-carousel__mask {
  background-color: #F3F3F3!important;
}
</style>
